import * as React from "react";
import { Link } from "gatsby";

import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function LaundryRoom() {
  return (
    <Layout>
      <Helmet title="Laundry Room | The Avett Brothers" />

      <h1>Laundry Room</h1>

      <h2>The Avett Brothers</h2>

      <h4>Key: G | Capo 4</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>
            <Ch>G</Ch>Don't <Ch>C</Ch>push me <Ch>G</Ch>out
          </p>
          <p>
            <Ch>G</Ch>Just a <Ch>C</Ch>little <Ch>G</Ch>longer
          </p>
          <p>
            <Ch>C</Ch>Stall your mother
          </p>
          <p>
            Disregard your father's <Ch>G</Ch>words
          </p>
          <p>
            <Ch>G</Ch>Close the <Ch>C</Ch>laundry <Ch>G</Ch>door
          </p>
          <p>
            <Ch>G</Ch>Tiptoe <Ch>C</Ch>across the <Ch>G</Ch>floor
          </p>
          <p>
            <Ch>C</Ch>Keep your clothes on
          </p>
          <p>
            I've got all that I can <Ch>G</Ch>take
          </p>
          <p>
            <Ch>C</Ch>Teach me how to use
          </p>
          <p>
            The love that people say you <Ch>G</Ch>made
          </p>
        </Verse>
        <Verse>
          <p>
            <Ch>G</Ch>Stop your <Ch>C</Ch>parents' <Ch>G</Ch>car
          </p>
          <p>
            <Ch>G</Ch>I just <Ch>C</Ch>saw a shooting <Ch>G</Ch>star
          </p>
          <p>
            <Ch>C</Ch>We can wish upon it
          </p>
          <p>
            We won't share the wish we <Ch>G</Ch>made
          </p>
          <p>
            <Ch>C</Ch>But I can't keep no secrets
          </p>
          <p>
            I wish that you would always <Ch>G</Ch>stay
          </p>
        </Verse>
        <Verse>
          <p>
            <Ch>G</Ch>Last night I <Ch>C</Ch>dreamt the whole night <Ch>G</Ch>
            long
          </p>
          <p>
            <Ch>G</Ch>I woke with a <Ch>C</Ch>head full of <Ch>G</Ch>songs
          </p>
          <p>
            <Ch>C</Ch>I spent the whole day
          </p>
          <p>
            I wrote 'em down, but it's a <Ch>G</Ch>shame
          </p>
          <p>
            <Ch>C</Ch>Tonight I'll burn the lyrics
          </p>
          <p>
            'Cause every chorus was your <Ch>G</Ch>name
          </p>
        </Verse>
        <Chorus>
          <p>
            <Ch>G</Ch>Break this <Ch>C</Ch>tired old <Ch>G</Ch>routine
          </p>
          <p>
            <Ch>G</Ch>And this <Ch>C</Ch>time don't make me <Ch>G</Ch>leave
          </p>
          <p>
            <Ch>G</Ch>I am a <Ch>C</Ch>breathing time <Ch>G</Ch>machine
          </p>
          <p>
            <Ch>G</Ch>I'll take you <Ch>C</Ch>all for a <Ch>G</Ch>ride
          </p>
          <p>
            <Ch>G</Ch>I am a <Ch>C</Ch>breathing time <Ch>G</Ch>machine
          </p>
          <p>
            <Ch>G</Ch>I'll take you <Ch>C</Ch>all for a <Ch>G</Ch>ride
          </p>
          <p>
            <Ch>G</Ch>Break this <Ch>C</Ch>tired old <Ch>G</Ch>routine
          </p>
          <p>
            <Ch>G</Ch>And this <Ch>C</Ch>time don't make me <Ch>G</Ch>leave
          </p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
